/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { isClientValidationError } from "utils/validation.utils";
import { UnsavedPushNotification, AnyPushNotification } from "api/push-notification";
import { PushNotificationServerValidationError, PushNotificationDateValidationError } from "./errors";
import { t } from "i18n";
import { WorkspaceRequestBody } from "api/workspace";
import { TargetPlatformError } from "./state";
import { isArray } from "lodash";

enum SERVER_ERRORS {
  CODE_PRESENCE = "presence",
  CODE_MAX_COUNT = "max-count"
}

export function hasError(field: string, error: Error | null): boolean {
  if (error && field !== "") {
    if (error instanceof PushNotificationServerValidationError) {
      switch (field) {
        case "title":
          return !!error.error.title;

        case "push-notification":
          return !!error.error["push-notification"];

        case "target-categories":
          return !!error.error["target-categories"];

        default:
          return false;
      }
    }
    if (isClientValidationError<UnsavedPushNotification>(error) && error.errors[field]) {
      return !!error.errors[field][0];
    }
  }
  return false;
}

export function getErrorMessage(field: string, error: Error | null) {
  const isChannelMissing = (targetPlatforms: TargetPlatformError[]) => {
    for (const targetPlatform of targetPlatforms) {
      if (targetPlatform && targetPlatform.channel) {
        return true;
      }
    }
    return false;
  };

  const isPlatformMissing = (targetPlatforms: TargetPlatformError[]) => {
    for (const targetPlatform of targetPlatforms) {
      if (targetPlatform && targetPlatform.platform) {
        return true;
      }
    }
    return false;
  };

  if (error === null) {
    return "";
  }
  if (error instanceof PushNotificationDateValidationError) {
    switch (field) {
      case "publish-at":
        return error.error;

      default:
        return "";
    }
  }
  if (error instanceof PushNotificationServerValidationError) {
    switch (field) {
      case "title":
        return error.error.title && error.error.title.code === SERVER_ERRORS.CODE_MAX_COUNT
          ? t("push-notification.messages.title-too-long")
          : "";

      case "push-notification":
        if (error.error["push-notification"])
          return error.error["push-notification"].code === SERVER_ERRORS.CODE_MAX_COUNT
            ? t("push-notification.messages.message-too-long")
            : error.error["push-notification"].code === SERVER_ERRORS.CODE_PRESENCE
            ? t("push-notification.messages.message-missing")
            : "";
        else return "";

      case "target-categories":
        return error.error["target-categories"]
          ? isArray(error.error["target-categories"])
            ? isChannelMissing(error.error["target-categories"])
              ? t("push-notification.messages.channels_missing")
              : isPlatformMissing(error.error["target-categories"])
              ? t("push-notification.messages.platform-missing")
              : ""
            : error.error["target-categories"].code === SERVER_ERRORS.CODE_PRESENCE
            ? t("push-notification.messages.targets-missing")
            : ""
          : "";

      default:
        return "";
    }
  }
  if (isClientValidationError<UnsavedPushNotification>(error) && error.errors[field]) {
    return error.errors[field][0];
  }
  return "";
}

export function pushNotificationtoUnsavedPushNotification(
  publishAt: number | null,
  pushNotification: AnyPushNotification
) {
  return {
    title: pushNotification.title,
    "push-notification": pushNotification["push-notification"],
    "story-content-id": pushNotification["story-content-id"],
    "publish-at": publishAt,
    "target-categories": pushNotification["target-categories"]
  };
}

export function generateWorkspaceRequestBody(
  term: string,
  pushNotificationStatus: string,
  offset: number
): WorkspaceRequestBody {
  return new WorkspaceRequestBody(
    "author-name,author-id,content-type,q,headline,message,is-published,linked-story-id,slug,story-content-id,story-template,updated-at,id",
    "dashboard",
    "push-notification",
    "updated-at",
    20,
    offset,
    [{ name: "default", "display-name": "Default", categories: [] }],
    pushNotificationStatus,
    term === "" ? null : term
  );
}
