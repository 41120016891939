/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { get } from "lodash";
import FieldContainer from "../field-container/field-container";
import { TemplateFieldErrors } from "../shared-types";
import { Fields } from "api/content-templates";
import { PartialAppState } from "pages/settings/initial-state";
import { connect } from "react-redux";
import CategoryTitle from "components/category-title/category-title";
import { t } from "i18n";

const templateFieldsToDisplay = [
  { label: "Title", path: "headline", hasCharacterLength: true },
  { label: "Subtitle", path: "subheadline", hasCharacterLength: true },
  { label: "Sections", path: "sections", hasCharacterLength: false },
  { label: "Tags", path: "tags", hasCharacterLength: false },
  // { label: "Subtitle", path: "instant-articles-video-fields" },
  { label: "Author", path: "author", hasCharacterLength: false },
  { label: "Social Share", path: "summary", hasCharacterLength: true },
  { label: "Canonical URL", path: "canonical-url", hasCharacterLength: false },
  { label: "Custom URL", path: "custom-url", hasCharacterLength: false },
  { label: "Sponsored By", path: "sponsored-by", hasCharacterLength: false },
  { label: "Story Element Image Alt Text", path: "image-alt-text", hasCharacterLength: true }
];

const seoFieldsToDisplay = [
  { label: "Meta Keywords", path: "meta-keywords", hasCharacterLength: false },
  { label: "Meta Description", path: "meta-description", hasCharacterLength: true },
  { label: "Meta Title", path: "meta-title", hasCharacterLength: true }
];

const templateImageFieldsToDisplay = [
  { label: "Hero Image", path: "hero-image-s3-key", hasCharacterLength: false },
  { label: "Hero Image Attribution", path: "hero-image-attribution", hasCharacterLength: true },
  { label: "Hero Image Caption", path: "hero-image-caption", hasCharacterLength: true },
  { label: "Hero Image Focus Point", path: "hero-image-focus-point", hasCharacterLength: false },
  { label: "Hero Image Alt Text", path: "hero-image-alt-text", hasCharacterLength: true }
];

const templateAlternativeFieldsToDisplay = [
  { label: "Story Alternative Hero Image", path: "home-alternative-hero-image-s3-key", hasCharacterLength: false },
  { label: "Story Alternative Headline", path: "home-alternative-headline", hasCharacterLength: true },
  { label: "Social Alternative Hero Image", path: "social-alternative-hero-image-s3-key", hasCharacterLength: false },
  { label: "Social Alternative Headline", path: "social-alternative-headline", hasCharacterLength: true }
];

interface OwnProps {
  onFieldChange: (key: string, value: Object) => void;
  fields: Fields;
  setErrors: (errors: Object) => void;
  errors: TemplateFieldErrors;
}

interface StateProps {
  isAlternativeHeadlineAndImageEnabled: boolean;
}

type Props = OwnProps & StateProps;

const TemplateFields: React.SFC<Props> = ({
  fields,
  onFieldChange,
  setErrors,
  errors,
  isAlternativeHeadlineAndImageEnabled
}) => {
  const isHeroImageHidden = get(fields, ["hero-image-s3-key", "validations", "hidden"]);
  return (
    <React.Fragment>
      {templateFieldsToDisplay.map((fieldToDisplay, index) => (
        <FieldContainer
          key={fieldToDisplay.path}
          id={index}
          fieldToDisplay={fieldToDisplay}
          field={fields[fieldToDisplay.path]}
          onFieldChange={onFieldChange}
          setErrors={setErrors}
          errors={errors}
        />
      ))}
      {!isHeroImageHidden && (
        <>
          <CategoryTitle title={t("settings.storyTemplates.categories.hero-image")} />
          {templateImageFieldsToDisplay.map((fieldToDisplay, index) => (
            <FieldContainer
              key={fieldToDisplay.path}
              id={index}
              fieldToDisplay={fieldToDisplay}
              field={fields[fieldToDisplay.path]}
              onFieldChange={onFieldChange}
              setErrors={setErrors}
              errors={errors}
            />
          ))}
        </>
      )}
      <>
        <CategoryTitle title={t("settings.storyTemplates.categories.seo")} />
        {seoFieldsToDisplay.map((fieldToDisplay, index) => (
          <FieldContainer
            key={fieldToDisplay.path}
            id={index}
            fieldToDisplay={fieldToDisplay}
            field={fields[fieldToDisplay.path]}
            onFieldChange={onFieldChange}
            setErrors={setErrors}
            errors={errors}
          />
        ))}
      </>
      {isAlternativeHeadlineAndImageEnabled && (
        <>
          <CategoryTitle title={t("settings.storyTemplates.categories.alternatives")} />
          {templateAlternativeFieldsToDisplay.map((fieldToDisplay, index) => (
            <FieldContainer
              key={fieldToDisplay.path}
              id={index}
              fieldToDisplay={fieldToDisplay}
              field={fields[fieldToDisplay.path]}
              onFieldChange={onFieldChange}
              setErrors={setErrors}
              errors={errors}
              hideFieldToggle={true}
            />
          ))}
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isAlternativeHeadlineAndImageEnabled: get(state.features, "isAlternativeHeadlineAndImageEnabled", false)
  };
};

export default connect(mapStateToProps)(TemplateFields);
