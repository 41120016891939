/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Image } from "api/search-media-image";
import { actions } from "pages/story-editor/actions";
import { goBack } from "connected-react-router";

import { PartialAppState } from "pages/story-editor/state";
import ImageUpload from "pages/media-library/components/image-upload/image-upload";

interface StateProps {
  uploading: boolean;
  selectedMedia: Image[] | null;
  isStoryImageHyperlinkEnabled: boolean;
}
interface DispatchProps {
  updateImageData: (key: string, value: any, imageIndex: number) => void;
  previousInspector: () => void;
  setSelectedInspectorImage: (images: Image[]) => void;
}

type StoryEditorHeroInspectorProps = StateProps & DispatchProps;

export const StoryImageInspector: React.FC<StoryEditorHeroInspectorProps> = ({
  selectedMedia,
  updateImageData,
  uploading,
  previousInspector,
  isStoryImageHyperlinkEnabled,
  setSelectedInspectorImage
}) => {
  return (
    <ImageUpload
      selectedImages={selectedMedia}
      updateImageData={updateImageData}
      isUploading={uploading}
      onDeleteMedia={previousInspector}
      isLinkAuthorEnabled={false}
      isStoryImageHyperlinkEnabled={isStoryImageHyperlinkEnabled}
      setSelectedMedia={(images) => setSelectedInspectorImage(images)}
    />
  );
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    selectedMedia: state.storyEditor.ui.imageForInspector,
    uploading: state.storyEditor.ui.imageUploading,
    isStoryImageHyperlinkEnabled: state.features.enableStoryImageHyperlink
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>): DispatchProps => {
  return {
    updateImageData: (key, value, imageIndex) =>
      dispatch({ type: actions.UPDATE_IMAGE_DATA_FOR_INSPECTOR, payload: { key, value, imageIndex } }),
    setSelectedInspectorImage: (image) => dispatch({ type: actions.SET_IMAGE_FOR_INSPECTOR, payload: { image } }),
    previousInspector: () => dispatch(goBack())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(StoryImageInspector);
