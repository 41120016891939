/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { validate } from "utils/validation.utils";
import { UnsavedPushNotification } from "api/push-notification";
import { ValidationResult } from "utils/validation.utils";
import { t } from "i18n";

export function validatePushNotification(
  pushNotification: UnsavedPushNotification,
  channelsEnabled: boolean
): ValidationResult<UnsavedPushNotification> {
  if (channelsEnabled) {
    return validate(pushNotification, {
      "push-notification": {
        presence: {
          allowEmpty: false,
          message: "^" + t("push-notification.messages.message-missing")
        }
      },
      "target-categories": {
        presence: {
          allowEmpty: false,
          message: "^" + t("push-notification.messages.channels_missing")
        }
      }
    });
  } else {
    return validate(pushNotification, {
      "push-notification": {
        presence: {
          allowEmpty: false,
          message: "^" + t("push-notification.messages.message-missing")
        }
      },
      "target-categories": {
        presence: {
          allowEmpty: false,
          message: "^" + t("push-notification.messages.platform-missing")
        }
      }
    });
  }
}
