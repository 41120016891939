/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Spinner from "components/spinner/spinner";
import TextArea from "components/text-area/text-area";
import TextField from "components/text-field/text-field";
import { t } from "i18n";
import get from "lodash/get";
import { setStoryElementError } from "pages/story-editor/action-creators";
import * as React from "react";
import { base64Decode, isBase64Encoded } from "utils";
import { fetchSocialEmbed, updateSocialEmbed } from "../../async-action-creators";
import BitGravityVideo from "../bitgravity-video/bitgravity-video";
import Embed from "../embed/embed";
import SandboxedJsEmbed from "../js-embed/sandboxed-js-embed";
import VideoStoryElement from "../video-story-element/video-story-element";
import styles from "./oembed.module.css";
import Button from "components/button/button";
import { openOEmbedVideoInspector } from "pages/story-editor/async-action-creators";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectIsReadOnly } from "pages/story-editor/selectors";
import VideoClip from "../video-clip/video-clip";

const PROVIDER_URLS = {
  tweet: ["metadata", "tweet-url"],
  "buzzsprout-podcast": ["metadata", "buzzsprout-url"],
  "tiktok-video": ["metadata", "tiktok-video-url"],
  "facebook-post": ["metadata", "facebook-url"],
  "facebook-video": ["metadata", "facebook-url"],
  instagram: ["metadata", "instagram-url"],
  "dailymotion-video": ["metadata", "dailymotion-url"],
  "dailymotion-embed-script": ["metadata", "dailymotion-url"],
  "youtube-video": ["url"],
  "soundcloud-audio": ["url"],
  "bitgravity-video": ["url"],
  jwplayer: ["metadata", "embed-code"],
  "vod-video": ["metadata", "embed-code"],
  "brightcove-video": ["metadata", "embed-code"],
  "dilmot-q-and-a": ["embed-js"],
  jsembed: ["embed-js"],
  "vidible-video": ["metadata", "vidible-video-id"],
  "vimeo-video": ["metadata", "vimeo-url"]
};

const getURLKey = (provider) => PROVIDER_URLS[provider];

const getProvider = (storyElement) => {
  if (storyElement.subtype === "video-clip") {
    return "video";
  } else {
    return storyElement.subtype || storyElement.type || storyElement.metadata.provider;
  }
};

const getURLValue = (storyElement) => {
  const provider = getProvider(storyElement);
  const url = get(storyElement, getURLKey(provider), null);

  if (provider === "vimeo-video") {
    const data = url || get(storyElement, ["embed-js"], null);
    const shouldBeDecoded = isBase64Encoded(data);
    return shouldBeDecoded ? base64Decode(data) : data;
  }
  if (
    provider === "vod-video" ||
    provider === "brightcove-video" ||
    provider === "jwplayer" ||
    provider === "dilmot-q-and-a" ||
    (provider === "jsembed" && url)
  ) {
    return base64Decode(url);
  }
  return url;
};

class OEmbed extends React.Component<any, any> {
  componentDidMount() {
    this.props.storyElement.type === "jsembed" &&
      !this.props.storyElement.subtype &&
      this.props.dispatch(
        setStoryElementError(this.props.storyElement.id, { hint: t("story-editor.story-element.js-embed-warning") })
      );
  }

  handleURLChange = (id, url) => this.props.dispatch(updateSocialEmbed(id, url));

  renderSocialEmbed = (storyElementId) => this.props.dispatch(fetchSocialEmbed(storyElementId));

  openOEmbedVideoSelector = (e: React.MouseEvent, storyElementClientId: string) =>
    this.props.dispatch(openOEmbedVideoInspector(e, storyElementClientId));

  handleKeyPress = (storyElement, e) => {
    if (e.charCode === 13) {
      this.renderSocialEmbed(storyElement.id);
    }
  };

  handlePaste = (id, e) => {
    e.preventDefault();
    const url = e.clipboardData.getData("text");
    this.handleURLChange(id, url);
    this.renderSocialEmbed(id);
  };

  handleOnBlur = (storyElementID) => this.renderSocialEmbed(storyElementID);

  renderEmbeddedElement = (provider, showPreview, storyElement, node) => {
    if (
      provider === "youtube-video" ||
      provider === "vod-video" ||
      provider === "brightcove-video" ||
      provider === "soundcloud-audio" ||
      provider === "dailymotion-video" ||
      provider === "jwplayer"
    ) {
      return <VideoStoryElement showPreview={showPreview} storyElement={storyElement} provider={provider} />;
    } else if (
      provider === "tweet" ||
      provider === "instagram" ||
      provider === "facebook-post" ||
      provider === "facebook-video" ||
      provider === "dilmot-q-and-a" ||
      provider === "dailymotion-embed-script" ||
      provider === "vidible-video" ||
      provider === "vimeo-video"
    ) {
      return <Embed embed={storyElement["embed-js"]} key={storyElement["embed-js"]} showPreview={showPreview} />;
    } else if ((provider === "jsembed" || storyElement.type === "jsembed") && showPreview) {
      return <SandboxedJsEmbed embed={storyElement["embed-js"]} key={storyElement["embed-js"]} />;
    } else if (provider === "bitgravity-video") {
      return <BitGravityVideo showPreview={showPreview} storyElement={storyElement} />;
    }
    return null;
  };

  render() {
    const { storyElement, node } = this.props;
    const url =
      this.props.storyElementUI.url || this.props.storyElementUI.url === ""
        ? this.props.storyElementUI.url
        : getURLValue(storyElement);

    const provider = getProvider(storyElement);

    const showPreview = !(this.props.storyElementUI.url || this.props.storyElementUI.url === "");
    const hasContent = storyElement["embed-url"] || storyElement["embed-js"] || storyElement["url"];

    return (
      <React.Fragment>
        <div>
          {provider !== "video" ? (
            <div>
              <div className={styles["oembed-url-field"]} data-test-id="oembed-url-field" contentEditable={false}>
                {!(
                  provider === "vod-video" ||
                  provider === "brightcove-video" ||
                  provider === "jwplayer" ||
                  provider === "dilmot-q-and-a" ||
                  provider === "vimeo-video"
                ) && (
                  <TextField
                    value={url || ""}
                    onChange={(url) => this.handleURLChange(storyElement.id, url)}
                    placeholder={t("story-editor.story-element.url-text")}
                    onKeyPress={(e) => this.handleKeyPress(storyElement, e)}
                    onPaste={(e) => this.handlePaste(storyElement.id, e)}
                    onBlur={() => this.handleOnBlur(storyElement.id)}
                    hint={this.props.storyElementUI.error && this.props.storyElementUI.error.hint}
                    errorMessage={this.props.storyElementUI.error && this.props.storyElementUI.error.message}
                    classname="oembed-text-field"
                    label=""
                    disabled={this.props.isReadOnly}
                  />
                )}
                {(provider === "vod-video" ||
                  provider === "brightcove-video" ||
                  provider === "jwplayer" ||
                  provider === "dilmot-q-and-a" ||
                  provider === "vimeo-video") && (
                  <TextArea
                    value={url || ""}
                    onChange={(url) => this.handleURLChange(storyElement.id, url)}
                    placeholder={t("story-editor.story-element.url-text")}
                    onKeyPress={(e) => this.handleKeyPress(storyElement, e)}
                    onPaste={(url) => this.handlePaste(storyElement.id, url)}
                    onBlur={() => this.handleOnBlur(storyElement.id)}
                    errorMessage={this.props.storyElementUI.error && this.props.storyElementUI.error.message}
                  />
                )}
                {!hasContent && !this.props.isReadOnly && (
                  <p className={styles["or-text"]} data-test-id="or-text">
                    {t("story-editor.story-element.or-choose-video-embed")}
                  </p>
                )}
              </div>
              {!hasContent && !this.props.isReadOnly && (
                <div className={styles["choose-video-button-wrapper"]} data-test-id="choose-video-button-wrapper">
                  <Button
                    classname={"choose-video-button"}
                    type={"default"}
                    onClick={(e) => this.openOEmbedVideoSelector(e, storyElement["client-id"])}>
                    {t("story-editor.story-element.choose-video-embed")}
                  </Button>
                </div>
              )}
              {this.props.storyElementUI.loading && <Spinner />}
              {this.renderEmbeddedElement(provider, showPreview, storyElement, node)}
              {hasContent && !this.props.isReadOnly && (
                <div
                  className={styles["video-replace-or-delete-buttons"]}
                  data-test-id="video-replace-or-delete-buttons">
                  <Button type={"default"} onClick={(e) => this.openOEmbedVideoSelector(e, storyElement["client-id"])}>
                    {t("story-editor.story-element.replace")}
                  </Button>
                  <Button
                    classname={"remove-video-button"}
                    type={"default"}
                    onClick={(e) => this.handleURLChange(storyElement.id, "")}>
                    {t("story-editor.story-element.remove")}
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <VideoClip storyElement={this.props.storyElement}></VideoClip>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isReadOnly: selectIsReadOnly(state)
  };
};

export default compose(connect(mapStateToProps))(OEmbed);
export { OEmbed };
