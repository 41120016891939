/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { UnsavedPushNotification, AnyPushNotification, PushNotificationListItem } from "api/push-notification";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { TabValue } from "./tab-list";
import { Aggregations } from "api/workspace";
import { FieldLimits } from "api/route-data/push-notification-route-data";
import { Features } from "api/route-data/route-data";

export interface PartialAppState {
  pushNotification: State;
  config: {
    fieldLimits: FieldLimits;
    publisherWideBannerMessage: string | null;
  };
  features: Features;
}

export interface State {
  pushNotifications: PushNotificationListItem[];
  totalCount: number;
  aggregations: Aggregations;
  app: {
    currentPushNotification: AnyPushNotification | null;
  };
  ui: {
    searchTerm: string;
    currentTab: TabValue;
    isInspectorActive: boolean;
    isPublishLater: boolean;
    details: LoaderState;
    main: LoaderState;
    save: LoaderState;
  };
}

export interface TargetPlatformError {
  channel?: ErrorCodeClass;
  platform?: ErrorCodeClass;
}

export interface PushNotificationValidationError {
  "push-notification"?: ErrorCodeClass;
  title?: ErrorCodeClass;
  "target-categories"?: ErrorCodeClass | TargetPlatformError[];
}

export interface ErrorCodeClass {
  code: string;
  threshold?: number;
}

export const NEW_PUSH_NOTIFICATION: UnsavedPushNotification = {
  title: "",
  "push-notification": "",
  "publish-at": null,
  "story-content-id": null,
  "target-categories": null
};

const pushNotifications: PushNotificationListItem[] = [];
export const INITIAL_PUSH_NOTIFICATION_STATE: State = {
  pushNotifications: pushNotifications,
  totalCount: 20,
  aggregations: {
    status: {
      failed: 0,
      scheduled: 0,
      published: 0,
      all: 0,
      open: 0,
      "needs-approval": 0
    }
  },
  app: {
    currentPushNotification: null
  },
  ui: {
    searchTerm: "",
    currentTab: TabValue.SCHEDULED,
    isInspectorActive: false,
    isPublishLater: false,
    details: INITIAL_LOADER_STATE,
    main: INITIAL_LOADER_STATE,
    save: INITIAL_LOADER_STATE
  }
};
